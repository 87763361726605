import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Bootstrap & Custom styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// Components
const CustomNavbar = lazy(() =>
  import("./Components/CustomNavbar/customNavbar.jsx")
);
const Footer = lazy(() => import("./Components/Footer/footer.jsx"));
const MentionsLegales = lazy(() =>
  import("./Components/MentionsLegales/MentionsLegales.jsx")
);
const CGV = lazy(() => import("./Components/CGV/cgv.jsx"));
const Policy = lazy(() => import("./Components/Policy/policy.jsx"));
const Remerciement = lazy(() =>
  import("./Components/Remerciement/remerciement.jsx")
);

// Pages
const Accueil = lazy(() => import("./Pages/Accueil/accueil.jsx"));
const Realisations = lazy(() =>
  import("./Pages/Realisations/realisations.jsx")
);
const Contact = lazy(() => import("./Pages/Contact/contact.jsx"));
const APropos = lazy(() => import("./Pages/APropos/A-Propos.jsx"));

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <div className="d-flex flex-column min-vh-100">
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <CustomNavbar />
            <main className="flex-grow-1">
              <Routes>
                <Route path="/" element={<Accueil />} />
                <Route path="/Realisations" element={<Realisations />} />
                <Route path="/APropos" element={<APropos />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/MentionsLegales" element={<MentionsLegales />} />
                <Route path="/CGV" element={<CGV />} />
                <Route path="/Policy" element={<Policy />} />
                <Route
                  path="/Contact-remerciement"
                  element={<Remerciement />}
                />
              </Routes>
            </main>
            <Footer />
          </Suspense>
        </Router>
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
